.new-user-link{
    /* margin-left:25%; */
    text-decoration:underline;
    line-height:0.8rem;
    border-width: 0px;
    background-color:#FFFFFF;
}
.merchant-link{
    text-decoration:underline;
    border-width: 0px;
    background-color:#FFFFFF;
}