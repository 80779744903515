.fontRoboto{
    font-family: 'Roboto', sans-serif;
}
.fontRubik{
    font-family: 'Rubik', sans-serif;
}
.sizeBig{
    font-size: 1.8rem;
    letter-spacing: 0.2px;
    font-weight: 500;
}
.sizeMed{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.1px;
}
.sizeMedSm{
    font-size: 0.965rem;
    line-height: 1.5;
    letter-spacing: 0.1px;
}
.sizeSm{
    font-size: .85rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
}
.dull{
  color: #738287;
}
.bold{
    font-weight: bold;
}
.green{
    color: #1DA448;
}

.grey{
    color: #676767;
}


.block{
    display: block;
}
