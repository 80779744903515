.basicCard{
    display: flex;
    flex-direction:row;
    justify-content:space-evenly;
    margin-top: 10px
}

.cardContent{
    flex-basis:0;
    flex-grow:0.7;
    display: flex;
    flex-direction:column;
}
.withBorder{
    border-right:0.5px solid #C5C5C5
}

.marginImage{
    margin-top:10%
}

.mainNumber{
    text-align:right;
    font-weight:bold;
    margin-top:10%;
    width:55%
}

.contentBody{
    text-align:center;
    margin-top:3%;
    color:#565656 !important; 
    margin-left: 5%;
    margin-right:5%
}

.numberStats{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.arrowImage{
    width:45%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.comparison_red{
    text-align: center;
    margin-top: 3%;
    margin-left: 5%;
    margin-right:5%;
    color:#FF5A5F !important;
}
.comparison_green{
    text-align: center;
    margin-top: 3%;
    margin-left: 5%;
    margin-right:5%;
    color:#3ed6ab !important;
}

