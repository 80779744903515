td{
    vertical-align: middle !important;
    border-bottom: 1px;
    text-align: center;
    font-size:0.8rem;
}
.paynow{
    margin: 20px 20px !important;
    padding:5px 10px !important
}
th{
    text-align: center;
}
.month-bar{
    margin-top:5px;
    background:#ff8600;
    border-radius: 25px;
    height:3px;
    width:90px;
    animation-name: revealRight;
    animation-duration:1s;  
}
@keyframes revealRight {
    from { width:0px}
    to {width:90px}
}
table{
    margin-top:20px;
    margin-bottom:30px !important;
}