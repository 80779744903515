.invoice {
  padding: 0px;
  max-width: 100%;

  @include directify($directions) {
    text-align: directed('left');
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}


@media screen and (min-width: 768px) {

  .invoice {
    padding: 50px;
  }

}

.dataTables_paginate {
  float: right;
}


.dataTables_filter {
  float: right;
}

.custom-select {
  margin-left: 0px !important;
}

.page-item.active .page-link {

  background-color: #ff8600;
  border-color: #ff8600;
}