.StripeElement {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #495057;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #CED4DA;
    border-radius:4px;
    padding: 8px 12px 6px 12px;
    width: 100%;
    margin:8px 0px 4px -1px;
    height:36px;
    box-sizing: border-box;
    transform: translateZ(0);
    -webkit-user-select:text;
  }

.StripeElement--focus{
    border:1px solid #80BDFF;
    box-shadow: 0 0 3.2px #007bff;
    outline:0;
    border-radius:4px;
    transition: box-shadow 0.15s;
}

.StripeElement--invalid{
    border: 1px solid #DC3545;
    padding: 8px 46px 6px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    color: #495057;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' fill='%23DC3545'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 5px;
    color: #495057;
}


.StripeElement--invalid.StripeElement--focus{
    box-shadow: 0 0 3.2px #DC3545;
    outline:0;
    border-radius:4px;
    transition: box-shadow 0.15s;
}
.StripeElement--complete{
    border: 1px solid #28A745;
    padding: 8px 46px 6px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #495057;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' fill='%2328A745'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 5px;
}

.StripeElement--complete.StripeElement--focus{
    box-shadow: 0 0 3.2px #28A745;
    outline:0;
    border-radius:4px;
    transition: box-shadow 0.15s;
}

#showDisplayFpx .StripeElement {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #495057;
    border: 1px solid #CED4DA;
    border-radius:4px;
    padding: 8px 12px;
    width: 100%;
    margin:8px 0px 4px -1px;
    height:36px;
    box-sizing: border-box;
  }

#showDisplayFpx .StripeElement--complete{
    border: 1px solid #28A745;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
}
#StripePaymentRequestButton.StripeElement{
    padding: 0;
    width: 100%;
    margin:0;
    height:36px;
    border:0;
    box-sizing: border-box;
}

#StripePaymentRequestButton.StripeElement--focus{
    box-shadow:none;
}

.textBoxWrapper + .textBoxWrapper{
    margin-bottom:16px
}