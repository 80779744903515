.rightCol{
    background: #f1f7f9;
    min-height: 100%;
}
.hr{
    color: #f1f7f9;
}
.midBlock{
    width: 52.5%;
}
.point{
    margin: 1.2rem 0;
    display: flex;
    align-items: center;
}
.pointNoMargin{
    display: flex;
    align-items: center;
}
.introText{
    width: 30vw;
    font-weight: 500;
}
.hr{
    width: 100%;
    margin-top:2rem
}
.points{
    
    margin: 1rem 0;
}
.pointText{
    margin-left: 1.3rem;
}
.instPoint{
    width: 97%;
    margin: .8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.instalmentName{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.instalment{
    height: 2.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.capital::first-letter{
    text-transform: capitalize;
}
.check{
    height: 2.8rem;
    display: flex;
    align-items: center;
}
.phone{
    width: 100%;
}
.ButtonContainer {
    display: flex;
}
.spanRedirect{
    color: #738287 !important;
    font-family: 'Roboto', sans-serif ;
}
@media screen and (max-width:700px) {
    .introText{
        width: auto;
        font-weight:500;
    }
    .midBlock{
        width: 90%;
    }
    .chooseInstalment{
        width: 88%;
        align-self: center;
        margin: 1.5rem 3%;
    }
}

a{

    color: #738287;
    font-family: 'Roboto', sans-serif ;
}
.opaque {
    opacity: 1 !important;
}

.listBtnWrapper {
    text-align: center;
}

.listButton {
    background-color: #ffffff;
    color: #000000;
    box-shadow: none;
    border-width: 2px;
    border-style: outset;
    border-color: #000000;
    text-transform: none;
    width: 45%;
    margin: 2%;
}

.listButtonDull {
    color: #738287;
    border-color: #738287;
}

.textBox-Stripe{
    height: 40px!important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 300 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: rgba(0, 0, 0, 0.87) !important;
    border: 0 !important;
    border-bottom: .1rem #c1ced3 solid !important;
    padding: .5rem 0 0.1rem 0 !important;
    width: 100% !important;
}