.status-PAID{
    color:#28a745!important;
    font-weight: 500;
}
.status-UPCOMING{
    color:#B1C3C8!important;
    font-weight: 500;
}
.status-LATE{
    color:red!important;
    font-weight: 500;
}
.status-CHARGE{
    color:orange!important;
    font-weight:500;
}