.datePicker{
    text-align: center !important;
    /* align-self: auto !important; */
}
.productSaleContainer{
    display: flex;
    margin-bottom: 1rem;

}
.productSales{  
    width:60% !important;
    margin-right: 1rem !important;
  }
